<template>
  <div>
    <div class="left">
      <div class="label" style="margin-right: 8px">计划月份</div>
      <a-button
        style="text-align: left; width: 160px"
        @click="visible = true"
      >{{ year+'年' + (month + 1) + '月' }}</a-button>

      <div class="label" style="margin-right: 8px;margin-left: 24px">填报人</div>
      <a-button style="text-align: left; width: 160px">{{ name }}</a-button>

      <div style="margin-left: auto">
        <ProjectSelector :disabled="disabled" @change="onSelectProject" />
      </div>
    </div>

    <Padding />

    <a-table
      bordered
      :data-source="totalList"
      :loading="loading"
      :pagination="false"
      rowKey="projectId"
    >
      <a-table-column key="projectDesignCode" title="设计编号" data-index="projectDesignCode"></a-table-column>
      <a-table-column key="projectName" title="项目名称" data-index="projectName"></a-table-column>
      <a-table-column key="productionUnitName2" title="部门" data-index="productionUnitName2"></a-table-column>
      <a-table-column
        key="productionMasterName"
        title="生产负责人"
        data-index="productionMasterName"
        align="center"
      ></a-table-column>
      <a-table-column key="projectAmount" title="项目金额(万元)" align="right">
        <template slot-scope="text">
          <span v-if="text.total"></span>
          <Money v-else :money="text.projectAmount" />
        </template>
      </a-table-column>
      <a-table-column key="progress" title="累计项目进度" align="center">
        <template slot-scope="text">
          <div v-if="typeof text.progress === 'number'" class="between">
            <a-progress :percent="typeof  text.monthlyProgress === 'number' ? text.monthlyProgress : text.progress" :showInfo="false" />
            <span style="color: rgba(0, 0, 0, 0.45); width: 50px">{{typeof  text.monthlyProgress === 'number' ? text.monthlyProgress : text.progress }}%</span>
          </div>
          <div v-else></div>
        </template>
      </a-table-column>

      <a-table-column-group key="group" v-if="columns.length > 0" :title="month + 1 + '月计划进度'">
        <a-table-column
          v-for="item in columns"
          :key="item.date"
          :title="renderMonth( item.date)"
          align="center"
        >
          <template slot-scope="text, record, index">
            <span
              v-if="Array.isArray(text.hisList) && text.hisList.find(t => t.date === item.date)"
            >
              <span v-if="disabled">
                <span v-if="text.total">
                  <Money :money="text.hisList.find(t => t.date === item.date).proportion" />
                </span>
                <span class="center" v-else>
                  <span>{{text.hisList.find(t => t.date === item.date).proportion}}%</span>
                  <a-icon
                    type="arrow-up"
                    style="color: red"
                    v-if="renderArrow(text.hisList, item.date) === 'up'"
                  />
                  <a-icon
                    type="arrow-down"
                    style="color: green"
                    v-if="renderArrow(text.hisList, item.date) === 'down'"
                  />
                  <span style="margin-left: 4px">
                    <Money :money="renderArrowValue(text, text.hisList, item.date)" />
                  </span>
                </span>
              </span>

              <span v-else>
                <span v-if="text.total">
                  <Money :money="text.hisList.find(t => t.date === item.date).proportion" />
                </span>

                <span class="center" v-else>
                  <a-input-number
                    size="small"
                    style="width: 60px;margin-right: 4px"
                    :formatter="$ratioFormatter"
                    :min="0"
                    :max="100"
                    :value="text.hisList.find(t => t.date === item.date).proportion"
                    @change="value => onChange(value, item.date, index)"
                    :disabled="(text.disabled && item.date !== now.format('YYYY-MM-DD')) || text.completionStatus === 'project_plan_completion_status_delay'"
                  />

                  <a-icon
                    type="arrow-up"
                    style="color: red"
                    v-if="renderArrow(text.hisList, item.date) === 'up'"
                  />
                  <a-icon
                    type="arrow-down"
                    style="color: green"
                    v-if="renderArrow(text.hisList, item.date) === 'down'"
                  />
                  <span style="margin-left: 4px">
                    <Money :money="renderArrowValue(text, text.hisList, item.date)" />
                  </span>
                </span>
              </span>
            </span>
            <span v-else>--</span>
          </template>
        </a-table-column>
      </a-table-column-group>

      <a-table-column key="remarks" title="备注">
        <template slot-scope="text">
          <span v-if="disabled">{{ text.remarks }}</span>
          <span v-else>
            <span v-if="text.total"></span>
            <a-textarea v-else v-model="text.remarks" placeholder="请输入" size="small" />
          </span>
        </template>
      </a-table-column>

      <a-table-column key="completionStatus" title="项目计划状态" align="center">
        <template slot-scope="text">
          <DataDictFinder
            dictType="project_plan_completion_status"
            :dictValue="text.completionStatus"
          />
        </template>
      </a-table-column>

      <a-table-column key="control" title="操作" align="center" width="60px" v-if="!disabled">
        <template slot-scope="text, record, index">
          <a
            href="#"
            class="danger"
            @click.prevent="delay(index)"
            v-if="text.disabled && !text.total && text.completionStatus !== 'project_plan_completion_status_delay'"
          >延期</a>
          <a
            href="#"
            class="danger"
            @click.prevent="remove(index)"
            v-if="!text.disabled && !text.total && !text.oriId"
          >删除</a>
        </template>
      </a-table-column>
    </a-table>

    <a-modal :visible="delayVisible" title="延期" :footer="null" @cancel="cancelDelay">
      
            <div style="margin-bottom: 8px; font-weight: bold">
              选择延期月份
            </div>

      <a-month-picker placeholder="" v-model="delayDate" :disabledDate="disabledDate" />
            <div style="padding-top: 16px"></div>

                     <div style="margin-bottom: 8px; font-weight: bold">
              选择延期进度
            </div>

              <a-input-number
                    :formatter="$ratioFormatter"
                    :min="0"
                    :max="lastProgress"
                    v-model="delayValue"
                  />
      


      <div class="right" style="margin-top: 16px">
        <a-space>
          <a-button @click="cancelDelay">关闭</a-button>
          <a-button type="primary" @click="saveDelay">确定</a-button>
        </a-space>
      </div>
    </a-modal>

    <a-modal :visible="visible" title="选择预填报年月" :footer="null" @cancel="visible = false">
      <a-month-picker placeholder="请选择" v-model="reportDate" :disabledDate="disabledDate2" />
      <div class="right" style="margin-top: 16px">
        <a-space>
          <a-button @click="visible = false">关闭</a-button>
          <a-button type="primary" @click="save">确定</a-button>
        </a-space>
      </div>
    </a-modal>
  </div>
</template>

<script>
import ProjectSelector from "./project-selector.vue";

import moment from "moment";
import accurate from "accurate";

export default {
  props: {
    year: {
      type: Number,
      default: 0
    },
    month: {
      type: Number,
      default: 0
    },
    date: {
      type: Number,
      default: 0
    },

    name: {
      type: String,
      default: ""
    },
    loading: {
      type: Boolean,
      default: false
    },
    list: {
      type: Array,
      default: () => []
    },
    disabled: {
      type: Boolean,
      default: false
    }
  },

  components: {
    ProjectSelector
  },

  data() {
    return {
      delayIndex: -1,
      delayVisible: false,
      delayDate: null,
      delayValue: 0,

      reportDate: null,
      visible: false
    };
  },

  computed: {
    now() {
      return moment(new Date(this.year, this.month, this.date));
    },

    columns() {
      let arr = [];
      for (let i = 0; i < this.list.length; i++) {
        const item = this.list[i];
        if (Array.isArray(item.hisList)) {
          arr.push(...item.hisList.map(item => item.date));
        }
      }
      arr = arr.filter((item, index, self) => self.indexOf(item) === index);
      arr.sort();

      return arr.map(date => {
        return {
          date
        };
      });
    },

    totalList() {
      if (this.list.length > 0) {
        const hisList = this.columns
          .map(item => item.date)
          .map(date => {
            let total = 0;

            this.list.forEach(element => {
              const projectAmount = element.projectAmount || 0;
              const e = element.hisList.find(e => e.date === date);
              if (e && e.proportion) {
                const result = accurate.expr(
                  `${projectAmount}*${e.proportion}*${0.01}`
                );
                total = accurate.expr(`${total}+${result}`);
              }
            });

            return {
              date,
              proportion: total
            };
          });

        return [
          ...this.list,
          {
            key: "total",
            projectDesignCode: "合计(万元)",
            total: true,
            hisList
          }
        ];
      } else {
        return [];
      }
    },

    lastProgress() {
          const item = this.list[this.delayIndex];
          if (item && item.hisList) {
            return  item.hisList[item.hisList.length - 1].proportion
          } else {
            return 0;
          }
    }
  },

  methods: {
    onSelectProject(project) {
      const item = this.list.find(item => item.projectId === project.id);
      if (item) {
        this.$message.error("请勿重复添加");
        return;
      }

      this.list.push({
        key: Date.now().toString() + Math.random(),

        projectDesignCode: project.designCode,
        projectName: project.name,

        projectId: project.id,
        contractId: project.contractId,

        productionUnitName2: project.contract
          ? project.contract.productionUnitName2
          : "",
        productionMasterName: project.contract
          ? project.contract.productionMasterName
          : "",

        projectAmount: project.projectAmount,
        progress: project.monthlyProgress,
        monthlyProgress: project.monthlyProgress,
        remarks: "",

        hisList: [
          {
            date: this.now.format("YYYY-MM-DD"),
            proportion: 0
          }
        ]
      });
      this.$emit("change", this.list);
    },

    remove(index) {
      this.list.splice(index, 1);
      this.$emit("change", this.list);
    },

    onChange(value, date, index) {
      let newValue = value;

      const item = this.list[index];

      const idx = item.hisList.findIndex(item => item.date === date);


        if (idx > 0) {
          const lastElement = item.hisList[idx - 1] || {};
          const proportion = lastElement.proportion || 0;
          if (value < proportion) {
            this.$message.error("项目进度不能低于之前填报的项目进度");
            newValue = proportion;
          }
        }
      // if (item.oriId) {
      //   console.log('是延期项目，可以向下调')
      // } else {

      // }



      const element = item.hisList[idx];

      const hisList = [...item.hisList];
      hisList.splice(idx, 1, {
        ...element,
        proportion: newValue
      });

      this.list.splice(index, 1, {
        ...item,
        hisList
      });

      this.$emit("change", this.list);
    },

    renderArrow(hisList, date) {
      const first = hisList[0];
      if (first.date === date) {
        return "";
      } else {
        const element = hisList.find(item => item.date === date);
        if (element) {
          if (element.proportion > first.proportion) {
            return "up";
          } else if (element.proportion < first.proportion) {
            return "down";
          } else {
            return "";
          }
        } else {
          return "";
        }
      }
    },

    renderArrowValue(element, hisList, date) {
      const projectAmount = element.projectAmount || 0;
      const e = hisList.find(e => e.date === date);
      if (e) {
        const result = accurate.expr(
          `${projectAmount}*${e.proportion}*${0.01}`
        );
        return result;
      } else {
        return 0;
      }
    },

    renderMonth(str) {
      return moment(str).format("M月D日");
    },

    delay(index) {

              const item = this.list[index];
              let lastValue = 0;
          if (item && item.hisList && item.hisList.length > 1) {
            lastValue = item.hisList[item.hisList.length - 1].proportion
           const lastValue2 = item.hisList[item.hisList.length - 2].proportion

           if (lastValue > lastValue2) {
            this.$message.error("延期的项目进度不能高于之前填报的项目进度");
            return;
           }
          } 

      this.delayValue = lastValue;
      this.delayIndex = index;
      this.delayVisible = true;
    },
    disabledDate(currentDate) {
      return (
        currentDate.valueOf() < moment().valueOf() ||
        currentDate.valueOf() >
          moment(moment().format("YYYY") + "-12")
            .add(1, "months")
            .valueOf()
      );
    },
    cancelDelay() {
      this.delayIndex = -1;
      this.delayVisible = false;
      this.delayDate = null;
      this.delayValue = 0;
    },
    saveDelay() {
      if (!this.delayDate) {
        this.$message.error("请选择延期年月");
        return;
      }
      if (typeof this.delayValue !== 'number') {
        this.$message.error("请填写延期进度");
        return;
      }

      const item = this.list[this.delayIndex];

      const hisList = [...item.hisList];

      const lastButOne = hisList[hisList.length - 2];

      hisList[hisList.length - 1].proportion = lastButOne.proportion - this.delayValue;
      // hisList[hisList.length - 1].proportion =  this.delayValue;

      this.list.splice(this.delayIndex, 1, {
        ...item,
        hisList,
        completionStatus: "project_plan_completion_status_delay",
        delayYear: parseInt(this.delayDate.format("YYYY")),
        delayMonth: parseInt(this.delayDate.format("M"))
      });
      this.$emit("change", this.list);
      this.cancelDelay();
    },

    disabledDate2(currentDate) {
      if (currentDate.format("YYYY-MM") === moment().format("YYYY-MM")) {
        return false;
      }
      return currentDate.valueOf() < moment().valueOf();
    },

    // setPreFill() {
    //   if (!this.disabled) {
    //     this.visible = true;
    //   } else {
    //     console.log("禁用状态无法预填报");
    //   }
    // },

    save() {
      this.$emit("changeDate", {
        year: parseInt(this.reportDate.format("YYYY")),
        month: parseInt(this.reportDate.format("M")),
        date: new Date().getDate()
      });
    }
  }
};
</script>